import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useDecision } from '@optimizely/react-sdk';
import { getPizzaRecipes } from '@/services/pizzaMenuService/menuService';
import {
  getMenuItems,
  onMenuInitialLoadAnalytics
} from '@/dataAnalytics/dataAnalyticsHelper';
import MenuTileList from '../../MenuTileList';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import CategoryTitle from '@/common/components/CategoryTitle';
import { MenuRecipe } from '../pizzaMenuTypes';
import { useApplicationInitialized } from '@/hooks/useApplicationInitialized';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';
import {
  MELTS,
  MELTS_RECIPE_PAGE_VIEW,
  PIZZA_RECIPE_PAGE_VIEW
} from '@/menu/pizza/constants';
import useDealPricingPrefix from '@/menu/deals/useDealPricingPrefix';
import { TAVERN_RECIPES_TITLE, SIGNATURE_RECIPES_TITLE } from '../constants';
import Routes from '@/router/routes';
import { useGetPizzaMenuCategories } from '@/menu/hooks/useGetPizzaMenuCategories';
import { SodiumWarningDisclaimer } from '@/clientCore/components/SodiumWarningDisclaimer/components/SodiumWarningDisclaimer';
import useSodiumAlertForStore from '@/clientCore/components/SodiumWarningDisclaimer/hooks/useSodiumAlertForStore';

interface Props {
  heading?: string;
  recipes: MenuRecipe[] | DealRecipe[] | undefined;
  onPizzaClick(pizza: MenuRecipe | DealRecipe): void;
}

const LocalizedPizzaMenu = ({
  heading = '', recipes, onPizzaClick
}: Props): JSX.Element => {
  const {
    createYourOwnPizza,
    popularPizzas,
    isLocalized
  } = getPizzaRecipes(recipes);
  const { tavernPizzas, signaturePizzas } = useGetPizzaMenuCategories(recipes);
  const analytics = useAnalytics();
  const [{ enabled: tavernNationalLaunchEnabled }] = useDecision('cb-tavern_national_launch', { autoUpdate: true });
  const { deal_id: dealId, deal_name: dealName } = analytics.analyticsDataModel.deal;
  const label = (dealId) ? 'Deals' : 'Pizza';
  const items = getMenuItems([...createYourOwnPizza, ...popularPizzas], label);
  const [pageLoadAnalyticsPushed, setPageLoadAnalyticsPushed] = useState(false);
  const pizzaRecipes = dealId ? createYourOwnPizza.concat(popularPizzas) : createYourOwnPizza;
  const pageHeading = dealId ? 'recipes' : heading;
  const appInitialized = useApplicationInitialized();
  const optimizelyInstance = getOrInitializeOptimizely();
  const pageView = dealName?.toLowerCase().includes(
    MELTS.toLowerCase()
  ) ? MELTS_RECIPE_PAGE_VIEW : PIZZA_RECIPE_PAGE_VIEW;

  const showPricingPrefixForDeal = useDealPricingPrefix(dealName);
  const currentRoute = dealId ? Routes.DEALS : Routes.MENU.PIZZA;
  const { isShowSodiumAlertForStore } = useSodiumAlertForStore();

  useEffect(() => {
    if (appInitialized && !pageLoadAnalyticsPushed) {
      analytics.push(() => onMenuInitialLoadAnalytics(
        label,
        items,
        dealId,
        dealName
      ));

      optimizelyInstance?.track(pageView);
      setPageLoadAnalyticsPushed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoadAnalyticsPushed, appInitialized, items]);

  return (
    <Grid data-testid="pizza-menu-page-localized">
      <CategoryTitle title={pageHeading} />
      <MenuTileList
        testId="cyo-pizzas"
        tileList={pizzaRecipes}
        isLocalized={isLocalized}
        handleClick={onPizzaClick}
        hasLinkEnabled
        hideDescription
        showPricePrefix={showPricingPrefixForDeal}
        currentRoute={currentRoute}
      />
      {!dealId && (
        tavernNationalLaunchEnabled ? (
          <>
            {!!tavernPizzas.length && (
              <>
                <Typography variant="h2">{TAVERN_RECIPES_TITLE}</Typography>
                <MenuTileList
                  testId="tavern-pizzas"
                  tileList={tavernPizzas}
                  handleClick={onPizzaClick}
                  hasLinkEnabled
                  hideDescription
                  isLocalized
                  showPricePrefix={showPricingPrefixForDeal}
                  currentRoute={currentRoute}
                />
              </>
            )}
            {!!signaturePizzas.length && (
              <>
                <Typography variant="h2">{SIGNATURE_RECIPES_TITLE}</Typography>
                <MenuTileList
                  testId="signature-pizzas"
                  tileList={signaturePizzas}
                  handleClick={onPizzaClick}
                  hasLinkEnabled
                  hideDescription
                  isLocalized
                  showPricePrefix={showPricingPrefixForDeal}
                  currentRoute={currentRoute}
                />
              </>
            )}
          </>
        ) : (
          <>
            {isLocalized && <Typography variant="h2">Popular pizzas</Typography>}
            <MenuTileList
              testId="popular-pizzas"
              tileList={popularPizzas}
              isLocalized={isLocalized}
              handleClick={onPizzaClick}
              hasLinkEnabled
              hideDescription
              currentRoute={currentRoute}
            />
          </>
        )
      )}
      <CaloriesDisclaimer />
      {isShowSodiumAlertForStore && <SodiumWarningDisclaimer />}
    </Grid>
  );
};

export default LocalizedPizzaMenu;
