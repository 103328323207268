import React from 'react';
import { sortBy } from 'remeda';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ProductId from '../../common/ProductId';
import MenuTile from '../MenuTile/MenuTile';
import { formatTestId } from '@/common/string-formatter';
import { MenuRecipe } from '../pizza/pizzaMenuTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import useOneClickAnalytics from '@/homepage/HutFavoritesSection/hooks/useOneClickAnalytics';

const styles = makeStyles(() => ({
  root: {
    paddingBottom: 30
  },
  hiddenTile: {
    display: 'none'
  }
}));

interface MenuTileListProps {
  currentRoute?: string;
  tileList: MenuRecipe[];
  handleClick: (tile: MenuRecipe, index?: number) => void;
  isLocalized?: boolean;
  hasLinkEnabled: boolean;
  tileRef?: React.MutableRefObject<HTMLElement[]>;
  testId?: string;
  hideDescription?: boolean;
  showPricePrefix?: boolean;
  expandedList?: boolean;
  minimumDisplayedItemsQuantity?: number;
  handleInfoClick?: (tile: MenuRecipe) => void;
  handleInfoDrawerLoaded?: (tile: MenuRecipe, position: number) => void;
  onGetStartedLinkClick?: () => void;
}

const MenuTileList = (props: MenuTileListProps): JSX.Element => {
  const {
    tileList,
    hasLinkEnabled,
    isLocalized,
    hideDescription,
    handleClick,
    tileRef,
    testId,
    showPricePrefix = true,
    expandedList = true,
    minimumDisplayedItemsQuantity = 0,
    handleInfoClick,
    handleInfoDrawerLoaded,
    currentRoute
  } = props;
  const classes = styles();

  const { onGetStartedLinkClick } = useOneClickAnalytics();

  const testIdPrefix = isLocalized ? 'localized' : 'national';

  return (
    <div
      data-testid={testId}
      className={classes.root}
    >
      {sortBy(tileList,
        (o) => o.priority,
        (o) => o.name?.toLowerCase()).map((tile, index) => {
        const {
          id, name, priority, outOfStock
        } = tile;
        const { globalId } = new ProductId(id);
        const tileName = formatTestId(name);
        const handleTileClick = () => {
          handleClick(tile, index);
        };

        const onInfoClick = () => {
          if (handleInfoClick) handleInfoClick(tile);
        };
        const onInfoDrawerLoaded = () => {
          if (handleInfoDrawerLoaded) handleInfoDrawerLoaded(tile, index);
        };

        const isHidden = !expandedList && index >= minimumDisplayedItemsQuantity;

        return (
          <Grid
            container
            data-testid={`menu-tile-container-${tileName}`}
            key={globalId}
            // eslint-disable-next-line no-param-reassign
            ref={(element) => {
              if (element && tileRef) tileRef.current[index] = element;
            }}
            className={clsx(isHidden && classes.hiddenTile)}
          >
            <MenuTile
              {...tile}
              currentRoute={currentRoute}
              name={tile.name}
              imageURL={tile.imageURL || ''}
              hideDescription={hideDescription}
              testId={`${testIdPrefix}-menu-tile-${tileName}`}
              handleClick={handleTileClick}
              hasLinkEnabled={outOfStock ? false : hasLinkEnabled}
              showPricePrefix={showPricePrefix}
              handleInfoClick={onInfoClick}
              handleInfoDrawerLoaded={onInfoDrawerLoaded}
              productId={tile.id}
              onGetStartedLinkClick={() => onGetStartedLinkClick(tile, index)}
              badges={{
                soldOut: outOfStock,
                vegetarian: tile?.vegetarian,
                popular: !isLocalized && priority === 0
              }}
            />
          </Grid>
        );
      })}
    </div>
  );
};
export default MenuTileList;
